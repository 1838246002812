import { useState } from 'react'

import styles from 'components/notifications/Notifications.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import { useNotificationsState } from './NotificationsStateProvider'
import { NotificationRow } from 'types/rows/NotificationRow'
import { toWarekiDatetime } from 'utils/dateUtil'
import { NotificationStateProvider } from '../notification/NotificationStateProvider'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import NotificationDialog from 'components/common/dialogs/NotificationDialog'
import PageTitle from 'components/common/PageTitle'
import { EXEC_TYPE } from 'types/common/ExecType'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

/**
 * 通知一覧画面フォーム
 * @returns
 */
const NotificationsForm = ({ forContractor = false }) => {
  const lStates = useLoginState()
  const states = useNotificationsState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)
  const [showAll, setShowAll] = useState<boolean>(false)

  const showDialogForInsert = () => {
    setExecType(() => EXEC_INSERT)
    openDialog()
  }

  // 全てのお知らせを表示する
  const showAllNotifications = () => {
    states.showAllNotifications()
    setShowAll(true)
  }

  // 最新の10件のお知らせを表示する
  const showCurrentsOnly = () => {
    states.refresh()
    setShowAll(false)
  }

  const selectNotification = (notification: NotificationRow) => {
    setExecType(() => EXEC_UPDATE)
    states.selectNotification(notification)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    states.refresh()
    setOpen(() => false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  const showInsertButton = lStates.isAdmin()
  const emptyMsg = lStates.isAdmin() ? null : 'お知らせはございません。'
  const insertBtnCaption = lStates.isAdmin() ? 'お知らせ新規登録' : showAll ? '最新の10件のみ' : '過去の記事を見る'
  const clickHandler = lStates.isAdmin() ? showDialogForInsert : showAll ? showCurrentsOnly : showAllNotifications
  const scrollable = forContractor ? tStyles.scrollable : ''

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      {lStates.isAdmin() && (
        <>
          <PageTitle title="お知らせ一覧"></PageTitle>
          {showInsertButton && (
            <div className={tStyles.tablePreInfo}>
              {/* 以下、右寄せのためのダミーのdiv */}
              <div></div>
              <div>
                <button className={tStyles.insertButton} onClick={showDialogForInsert}>
                  お知らせ新規登録
                </button>
              </div>
            </div>
          )}
          <div className={`${tStyles.tableFrame} ${scrollable}`}>
            <div className={tStyles.commonTable}>
              <div className={tStyles.headerRow}>
                <div className={`${tStyles.headerColumn} ${styles.adminCol1}`}>#</div>
                <div className={`${tStyles.headerColumn} ${styles.adminCol2}`}>掲載日</div>
                <div className={`${tStyles.headerColumn} ${styles.adminCol3}`}>タイトル</div>
                <div className={`${tStyles.headerColumn} ${styles.adminCol4}`}>詳細内容</div>
              </div>
              {states.notificationList.map((notification: NotificationRow, index) => {
                return (
                  <div
                    className={tStyles.dataRow}
                    key={notification.notificationId}
                    onClick={(_) => selectNotification(notification)}
                  >
                    <div className={`${tStyles.dataColumn} ${styles.adminCol1}`}>{index + 1}</div>
                    <div className={`${tStyles.dataColumn} ${styles.adminCol2}`}>
                      {toWarekiDatetime(new Date(notification.publishDatetime))}
                    </div>
                    <div className={`${tStyles.dataColumn} ${styles.adminCol3}`}>{notification.title}</div>
                    <div className={`${tStyles.dataColumn} ${styles.adminCol4}`}>
                      {notification.description}
                    </div>
                  </div>
                )
              })}
              <EmptyList isEmpty={states.isListEmpty} msg={emptyMsg} />
            </div>
          </div>
        </>
      )}
      {!lStates.isAdmin() && (
        <>
          <div className={`${tStyles.tableFrame} ${tStyles.topPageTitle}`}>
            <div style={{lineHeight: "1.2", fontSize: "30px", fontWeight: "300", letterSpacing: "5px", borderBottom: "solid 2px black"}}>
            お知らせ&nbsp;&nbsp;
            </div>
            <button className={tStyles.topPageSwitchList} onClick={clickHandler}>
              {insertBtnCaption}
            </button>
          </div>
          <div className={`${tStyles.tableFrame} ${scrollable}`}>
            <div className={tStyles.topPageTable}>
              {states.notificationList.map((notification: NotificationRow, index) => {
                return (
                  <div
                    className={tStyles.dataRow}
                    key={notification.notificationId}
                    onClick={(_) => selectNotification(notification)}
                  >
                    <div className={`${tStyles.dataColumn} ${styles.col1}`}>
                    {toWarekiDatetime(new Date(notification.publishDatetime))}
                    </div>
                    <div className={`${tStyles.dataColumn} ${styles.col2}`}>{notification.title}</div>
                  </div>
                )
              })}
              {states.isListEmpty && (
                <EmptyList isEmpty={states.isListEmpty} msg={emptyMsg} />
              )}
            </div>
          </div>
        </>
      )}

      {/* ==== 通知登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <NotificationStateProvider>
        <NotificationDialog
          execType={execType}
          notificationInfo={states.targetNotification!}
          show={open}
          onClose={closeDialog}
        />
      </NotificationStateProvider>
      {/* ここまで */}
    </>
  )
}

export default NotificationsForm
