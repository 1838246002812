import { useEffect } from 'react'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { EXEC_TYPE } from 'types/common/ExecType'
import { Transition } from './CommonTransition'
import { MailTemplateRow } from 'types/rows/MailTemplateRow'
import MailTemplateForm from 'components/mailTemplates/mailTemplate/MailTemplateForm'
import { useMailTemplateState } from 'components/mailTemplates/mailTemplate/MailTemplateStateProvider'

interface MailTemplateDialogProps {
  show: boolean
  execType: EXEC_TYPE
  mailTemplateInfo?: MailTemplateRow
  onClose?: () => void
}

const MailTemplateDialog = (props: MailTemplateDialogProps) => {
  const { show, execType, mailTemplateInfo, onClose } = props
  //
  const states = useMailTemplateState()

  // 初期化
  useEffect(() => {
    // クローズ時は入力値をクリア
    if (show === false) {
      states.clear()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /**
   *
   */
  const handleClose = () => {
    onClose!()
  }

  return (
    <div>
      <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              メールテンプレート情報
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              <span style={{fontSize: "18px"}}>閉じる</span>
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MailTemplateForm
            execType={execType}
            mailTemplateInfo={mailTemplateInfo}
            doClose={() => onClose!()}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default MailTemplateDialog
