import { Helmet } from 'react-helmet-async'
import DailyReservationsForm from 'components/reservations/reservations/daily/DailyReservationsForm'
import { DailyReservationsStateProvider } from 'components/reservations/reservations/daily/DailyReservationsStateProvider'
import { TITLE_RESERVE_BY_FACILITY } from 'utils/constants'

/**
 * 日別予約状況一覧画面
 */
const DailyReservations = () => {
  return (
    <DailyReservationsStateProvider>
      <Helmet>
        <title>{TITLE_RESERVE_BY_FACILITY}</title>
      </Helmet>
      <DailyReservationsForm />
    </DailyReservationsStateProvider>
  )
}

export default DailyReservations
