import { useEffect } from 'react'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { useUploadFileState } from 'components/uploads/uploadFile/UploadFileStateProvider'
import { EXEC_TYPE } from 'types/common/ExecType'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { Transition } from './CommonTransition'
import UploadFileForm from 'components/uploads/uploadFile/UploadFileForm'

/**
 *
 */
interface UploadFileDialogProps {
  show: boolean
  execType: EXEC_TYPE
  uploadFileInfo?: DownloadFileInfo
  onClose?: () => void
}

/**
 *
 * @param props
 * @returns
 */
const UploadFileDialog = (props: UploadFileDialogProps) => {
  const { show, execType, uploadFileInfo, onClose } = props
  //
  const states = useUploadFileState()

  // 初期化
  useEffect(() => {
    // クローズ時は入力値をクリア
    if (show === false) {
      states.clear()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /**
   *
   */
  const handleClose = () => {
    onClose!()
  }

  return (
    <div>
      <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              アップロードファイル情報
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              <span style={{fontSize: "18px"}}>閉じる</span>
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <UploadFileForm
            execType={execType}
            uploadFileInfo={uploadFileInfo!}
            doClose={handleClose}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default UploadFileDialog
