import { API_RESPONSE } from 'types/common/Api'
import axios from './common/axios'
import { RESPONSE_NG } from 'utils/constants'
import { INQUIRY } from './common/url'
import { InquiryInfo } from 'types/infos/InquiryInfo'

/**
 *
 * @param params
 * @returns
 */
export const sendInquiry = async (params: InquiryInfo) => {
  try {
    type retType = API_RESPONSE<void>
    const response = await axios.post<retType>(INQUIRY, params)

    // エラーチェックは読み出し元で実施する
    return response.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
