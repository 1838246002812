import { useEffect } from 'react'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { EXEC_TYPE } from 'types/common/ExecType'
import { Transition } from './CommonTransition'
import { ReservationSuspendListElement } from 'types/ui/ReservationSuspendListElement'
import { useReservationSuspendState } from 'components/facilities/suspend/ReservationSuspendStateProvider'
import ReservationSuspendForm from 'components/facilities/suspend/ReservationSuspendForm'

/**
 *
 */
interface ReservationSuspendDialogProps {
  show: boolean
  execType: EXEC_TYPE
  reservationSuspendInfo?: ReservationSuspendListElement
  onClose?: () => void
}

/**
 *
 * @param props
 * @returns
 */
const ReservationSuspendDialog = (props: ReservationSuspendDialogProps) => {
  const { show, execType, reservationSuspendInfo, onClose } = props
  //
  const states = useReservationSuspendState()

  // 初期化
  useEffect(() => {
    // クローズ時は入力値をクリア
    if (show === false) {
      states.clearAll()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /**
   *
   */
  const handleClose = () => {
    onClose!()
  }

  return (
    <div>
      <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              施設利用停止情報
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              <span style={{fontSize: "18px"}}>閉じる</span>
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReservationSuspendForm
            execType={execType}
            suspendInfo={reservationSuspendInfo}
            doClose={handleClose}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default ReservationSuspendDialog
