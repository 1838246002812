import parse from 'html-react-parser'
import styles from './OutputLabel.module.scss'

interface OutputLabelProps {
    value: string
    align?: 'left' | 'center' | 'right'
    width?: number
  }
  const OutputLabel = (props: OutputLabelProps) => {
    const { value, align, width } = props
    const textAlign = align ?? 'left'
    const textWidth = width ?? 100
    const strValue = value.replaceAll('\n', '<br/>')

    return (
      <div className={styles.outputLabel} style={{ textAlign, width: `${textWidth}px`, overflowWrap: "break-word" }}>
        {parse(strValue)}
      </div>
    )
  }
  
  export default OutputLabel
