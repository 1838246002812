import { ChangeEvent } from 'react'
import { Tooltip } from 'react-tooltip'
import styles from 'components/common/atoms/DropDown.module.scss'
import type { ListElement } from 'types/common/ListElement'

interface Props {
  id: string
  size?: number
  fontSize?: number
  showEmptyOption?: boolean
  emptyOptionLabel?: string
  list: ListElement[]
  value: string // 表示値
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void // 入力イベントハンドラ
  disabled?: boolean

  // エラー情報
  errorMsg?: string
}

const DropDown = ({
  id,
  size,
  fontSize,
  showEmptyOption,
  emptyOptionLabel,
  list,
  value,
  onChange,
  disabled,
  errorMsg
}: Props) => {
  //
  const width = (size ?? 200) + 'px'
  const fSize = (fontSize ?? 20) + 'px'
  const isShowEmptyOption = !!showEmptyOption
  const emptyLabel = emptyOptionLabel ?? '(選択)'
  const bgColor = !!errorMsg ? { backgroundColor: 'pink' } : {}
  const isShowTooltip = !errorMsg
  const isDisabled = !!disabled
  const selectOption = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(event)
  }

  return (
    <div className={styles.selectContainer} style={bgColor}>
      <select
        className={styles.select}
        style={{ width: width, fontSize: fSize }}
        value={value}
        data-tooltip-id={`dropdown-tooltip_${id}`}
        onChange={(event) => selectOption(event)}
        disabled={isDisabled}
      >
        {isShowEmptyOption && (
          <option value="0" key="">
            {emptyLabel}
          </option>
        )}
        {list.map((e: ListElement) => (
          <option value={e.value} key={e.value}>
            {e.label}
          </option>
        ))}
      </select>
      <Tooltip
        id={`dropdown-tooltip_${id}`}
        delayShow={300}
        hidden={isShowTooltip}
        place="top-start"
        style={{
          backgroundColor: 'lightgray',
          borderRadius: '10px',
          color: 'red'
        }}
      >
        <h3>{errorMsg}</h3>
      </Tooltip>
    </div>
  )
}
export default DropDown
