import PageTitle from 'components/common/PageTitle'
import CheckBox from 'components/common/atoms/CheckBox'
import InputText from 'components/common/atoms/InputText'
import Label from 'components/common/atoms/Label'
import RadioButtonGroup from 'components/common/atoms/RadioButtonGroup'

import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import D1pDatePicker from 'components/common/atoms/D1pDatePicker'
import { useContractorState } from 'components/contractors/contractor/ContractorStateProvider'

import fStyles from 'styles/utils/form.module.scss'
import type { ContractorInfo } from 'types/infos/ContractorInfo'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { useEffect } from 'react'

interface ContractorFormProps {
  execType: EXEC_TYPE
  isOpenForDialog?: boolean
  contractorInfo?: ContractorInfo
  doClose?: () => void
}

const ContractorForm = (props: ContractorFormProps) => {
  const { execType, isOpenForDialog, contractorInfo, doClose } = props

  const states = useContractorState()

  const isInsert = execType === EXEC_INSERT
  const isUpdate = execType === EXEC_UPDATE
  // 表示対象の契約社情報が「管理者」のものであるか？
  const isAdminRecord = states.coopType === 9

  // 画面初期化
  useEffect(() => {
    // 新規登録時
    if (execType === EXEC_INSERT) {
      states.refresh(execType)
      return
    }

    // 更新時
    if (execType === EXEC_UPDATE) {
      states.refresh(execType, contractorInfo)
    }
  }, [])

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doCallback()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
    doClose!()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />

      {!isOpenForDialog && <PageTitle title="契約社登録" />}
      <section className={fStyles.detailForm}>
        <div className={fStyles.topMargin} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="契約社名" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="contractName"
              size={45}
              maxLength={80}
              value={states.contractorName}
              onChange={(event) => states.changeContractorName(event.target.value)}
              errorMsg={states.msg4ContractorName}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="ログインID" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="loginId"
              size={20}
              maxLength={20}
              value={states.loginId}
              onChange={(event) => states.changeLoginId(event.target.value)}
              errorMsg={states.msg4LoginId}
            />
          </span>
        </div>

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="パスワード" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="password"
              type="password"
              size={20}
              maxLength={20}
              value={states.password}
              onChange={(event) => states.changePassword(event.target.value)}
              errorMsg={states.msg4Password}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="パスワード（確認）" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="passwordConfirm"
              type="password"
              size={20}
              maxLength={20}
              value={states.passwordConfirm}
              onChange={(event) => states.changePasswordConfirm(event.target.value)}
              errorMsg={states.msg4PasswordConfirm}
            />
          </span>
        </div>

        {isUpdate && (
          <div className={fStyles.row}>
            <span className={fStyles.label}></span>
            <span
              className={fStyles.input}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: '5px'
              }}
            >
              <div className={fStyles.infoMessage}>
                セキュリティ上、登録済みのパスワードを表示出来ません。
              </div>
              <div className={fStyles.infoMessage}>
                パスワードを変更する場合のみ、上記の２項目に新パスワードを入力してください。
              </div>
            </span>
          </div>
        )}

        {!isAdminRecord && (
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="ルームNo" required />
            </span>
            <span className={fStyles.input}>
              <InputText
                id="roomNo"
                size={4}
                maxLength={4}
                value={states.roomNo}
                onChange={(event) => states.changeRoomNo(event?.target.value)}
                errorMsg={states.msg4RoomNo}
              />
              <span>号室</span>
            </span>
          </div>
        )}

        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="契約開始日" required />
          </span>
          <span className={fStyles.input}>
            <D1pDatePicker
              id="usageStartDate"
              clearButton
              selected={states.usageStartDate}
              onChange={(event) => states.changeUsageStartDate(event)}
              errorMsg={states.msg4StartDate}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="契約終了日" />
          </span>
          <span className={fStyles.input}>
            <D1pDatePicker
              id="usageEndDate"
              clearButton
              selected={states.usageEndDate}
              onChange={(event) => states.changeUsageEndDate(event)}
              errorMsg={states.msg4EndDate}
            />
          </span>
          <span className={fStyles.output}>未定の場合は未設定で結構です。</span>
        </div>
        {!isAdminRecord && (
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="企業種別" required />
            </span>
            <span className={fStyles.input}>
              <RadioButtonGroup
                id="coopType"
                name="COOP_TYPE"
                value={states.coopType}
                elements={states.coopTypes}
                errorMsg={states.msg4CoopType}
                onChange={(event) => states.changeCoopType(event.target.value)}
              />
            </span>
          </div>
        )}

        {/* <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="担当メールアドレス" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="email"
              size={45}
              maxLength={64}
              value={states.email}
              onChange={(event) => states.changeEmail(event.target.value)}
              errorMsg={states.msg4Email}
            />
          </span>
        </div> */}
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="担当者名" />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="name"
              size={45}
              maxLength={50}
              value={states.name}
              placeholder={'（予約者の初期値）'}
              onChange={(event) => states.changeName(event.target.value)}
              errorMsg={states.msg4Name}
            />
          </span>
        </div>
        {/* <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="担当電話番号" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="tel"
              size={45}
              maxLength={14}
              value={states.tel}
              placeholder={'（ハイフン区切り。予約者の初期値）'}
              onChange={(event) => states.changeTel(event.target.value)}
              errorMsg={states.msg4Tel}
            />
          </span>
        </div> */}
        {!isAdminRecord && (
          <div className={fStyles.row}>
            <span className={fStyles.label}>
              <Label title="使用停止" />
            </span>
            <span className={fStyles.input}>
              <CheckBox
                id="isEnabled"
                checked={states.isSuspended}
                label="使用停止"
                onChange={(event) => states.changeIsSuspended(event.target.checked)}
              />
            </span>
            <span className={fStyles.output}>契約社はシステムにログインできなくなります</span>
          </div>
        )}

        <div className={fStyles.footerButtons}>
          {isInsert && (
            <button className={fStyles.button} onClick={states.insert}>
              新規登録
            </button>
          )}
          {isUpdate && (
            <button className={fStyles.button} onClick={states.update}>
              更新
            </button>
          )}
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
          {isUpdate && (
            <>
              {!isAdminRecord && (
                <button className={`${fStyles.button} ${fStyles.delete}`} onClick={states.remove}>
                  契約社削除
                </button>
              )}
            </>
          )}
        </div>
      </section>
    </>
  )
}

export default ContractorForm
