import { createContext, useContext, useEffect, useState } from 'react'

import { getFacilityList } from 'api/common/masterCache'
import { findDailyReservedList } from 'api/reservations'
import { ProviderProps } from 'types/common/ProviderProps'
import { ReservedInfo } from 'types/ui/ReservedInfo'
import {
  clearTimes,
  diffByTimeUnit,
  endTime,
  nextDate,
  nextMonth,
  nextWeek,
  prevDate,
  prevMonth,
  prevWeek,
  toTimeUnit
} from 'utils/dateUtil'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { findForDay } from 'api/reservationSuspended'
import { SuspendedInfo } from 'types/ui/SuspendedInfo'
import { FacilityListElement } from 'types/rows/FacilityListElement'
import { cloneDeep } from 'lodash'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

interface SuspendedFixed {
  startUnit: number
  timeUnit: number
}
interface StateProps {
  facilitiesList: FacilityListElement[]
  reservedList: ReservedInfo[]
  suspendedList: SuspendedInfo[]
  targetReservation: ReservedInfo | null
  targetDate: Date

  changeTargetDate: (date: Date | null) => void
  selectTargetReservation: (target: ReservedInfo) => void

  goPrevMonth: () => void
  goPrevWeek: () => void
  goPrevDay: () => void
  goToday: () => void
  goNextDay: () => void
  goNextWeek: () => void
  goNextMonth: () => void
  refresh: () => void
  refreshSuspended: () => void

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  checkCurrentTime: (datetime: Date) => boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const DailyReservationsStateContext = createContext({} as StateProps)

/**
 *
 * @returns
 */
export const useDailyReservationsState = () => useContext(DailyReservationsStateContext)

/**
 *
 * @param props
 * @returns
 */
export const DailyReservationsStateProvider = (props: ProviderProps) => {
  // Properties
  const { children } = props

  const gStates = useGlobalState()
  const loginState = useLoginState()

  // States
  const [facilitiesList, setFacilitiesList] = useState([] as FacilityListElement[])
  const [targetDate, setTargetDate] = useState<Date | null>(null)
  const [reservedList, setReservedList] = useState<ReservedInfo[]>([])
  const [suspendedList, setSuspendedList] = useState<SuspendedInfo[]>([])
  const [targetReservation, setTargetReservation] = useState<ReservedInfo | null>(null)

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  // ========== 初期化
  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    const func = async () => {
      try {
        const list = await getFacilityList()
        const myList = list.map((e) => {
          return { facilityId: e.value as number, facilityName: e.label }
        })
        setFacilitiesList(myList)
      } catch (e: unknown) {
        //
      }
    }
    func()
  }, [])

  /**
   * 対象日付の変更 -> その日付の予約リストを再取得
   */
  useEffect(() => {
    if (facilitiesList.length === 0) return
    if (targetDate === null) return
    refresh()
    refreshSuspended()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate, facilitiesList])

  // ========== チェンジイベントハンドラ
  // 日付変更イベント
  /**
   *
   * @param date
   */
  const changeTargetDate = (date: Date | null) => {
    setTargetDate(date)
  }

  // ========== クリックイベントハンドラ
  const goPrevMonth = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(prevMonth(targetDate))
  }

  const goPrevWeek = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(prevWeek(targetDate))
  }

  /**
   *
   * @returns
   */
  const goPrevDay = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(prevDate(targetDate))
  }

  /**
   *
   */
  const goToday = () => {
    changeTargetDate(new Date())
  }

  /**
   *
   * @returns
   */
  const goNextDay = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(nextDate(targetDate))
  }

  const goNextWeek = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(nextWeek(targetDate))
  }

  const goNextMonth = () => {
    if (!targetDate) {
      changeTargetDate(new Date())
      return
    }
    changeTargetDate(nextMonth(targetDate))
  }

  /**
   * 予約済みセルのクリックイベント
   * @param target
   */
  const selectTargetReservation = (target: ReservedInfo) => {
    setTargetReservation(target)
  }

  // ========== プライベート関数
  /**
   * 対象の日付の予約リストを取得して保持する。
   */
  const refresh = () => {
    try {
      gStates.startLoading()
      const contractorId = loginState.loginContractorId!
      setReservedList([])
      findDailyReservedList(targetDate!, contractorId)
        .then((list) => {
          // rowIndexの解決
          const newList = list.map((r) => {
            const id = r.facilityId
            const idx = facilitiesList.findIndex((f) => f.facilityId === id)
            r.rowIndex = idx
            r.isOwner = r.contractorId === loginState.loginContractorId
            return r
          })
          // 予約リストを更新
          setReservedList(newList)
        })
        .catch((e: unknown) => {
          // システムエラーメッセージの表示
          showFatalErrorMessage()
        })
    } finally {
      gStates.stopLoading()
    }
  }

  /**
   * 対象日付の利用停止情報を取得して保持する。
   */
  const refreshSuspended = () => {
    setSuspendedList([])
    findForDay(targetDate!)
      .then((list) => {
        // 全施設が対象の場合 -> 全ての施設のレコードに展開する
        const exchanged: SuspendedInfo[] = []
        list.forEach((s) => {
          if (s.facilityId !== null) {
            exchanged.push(s)
            return
          }
          // 施設IDがnull => 全施設が対象
          facilitiesList.forEach((f) => {
            const rInfo = cloneDeep(s)
            rInfo.facilityId = f.facilityId
            exchanged.push(rInfo)
          })
        })

        // 終日扱いと日跨ぎの利用停止の処理
        const newList = exchanged.map((e) => {
          // 行INDEXの設定
          e.rowIndex = facilitiesList.findIndex((f) => f.facilityId === e.facilityId)
          // Date変換
          e.suspendStartDatetime = new Date(e.suspendStartDatetime)
          e.suspendEndDatetime = new Date(e.suspendEndDatetime)
          // 導出項目の算出
          const { startUnit, timeUnit } = deriveValues(
            e.suspendStartDatetime,
            e.suspendEndDatetime,
            e.isAllDay
          )
          e.startUnit = startUnit
          e.timeUnit = timeUnit

          return e
        })

        setSuspendedList(newList)
      })
      .catch((e: unknown) => {
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  /**
   *
   * @param from
   * @param to
   * @param isAllDay
   * @returns
   */
  const deriveValues = (from: Date, to: Date, isAllDay: boolean): SuspendedFixed => {
    // 処理日
    const fromDate = clearTimes(targetDate!)
    const toDate = endTime(fromDate)
    // 終日
    if (isAllDay) {
      return { startUnit: toTimeUnit(fromDate), timeUnit: 24 * 2 }
    }

    // 開始日が当日よりも前の日付の場合
    const sDate = from < fromDate ? fromDate : from
    const eDate = to > toDate ? toDate : to
    const diff = diffByTimeUnit(eDate, sDate)

    return { startUnit: toTimeUnit(sDate), timeUnit: diff }
  }

  /**
   * 指定された日時が予約可能時間帯を経過している場合は、エラー終了する。
   * @param datetime
   * @returns
   */
  const checkCurrentTime = (datetime: Date): boolean => {
    const diff = (new Date().getTime() - datetime.getTime()) / 1000 / 60
    // 時刻差が30分を超えている
    // -> 指定した時間帯は、表示時には予約可能だったが、クリック時には既に使用可能時間帯を経過してしまっている
    if (diff > 30) {
      setDialogTitle('予約可能時間超過')
      setDialogContents('予約可能な時刻を過ぎています。画面から対象時間帯を指定し直して下さい。')
      setShowErrorDialog(true)
      refresh()
      refreshSuspended()
      return false
    }
    return true
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  // ========== 公開する
  const globalStates = {
    facilitiesList,
    reservedList,
    suspendedList,
    targetReservation,
    targetDate,

    changeTargetDate,
    selectTargetReservation,

    goPrevMonth,
    goPrevWeek,
    goPrevDay,
    goToday,
    goNextDay,
    goNextWeek,
    goNextMonth,
    refresh,
    refreshSuspended,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    checkCurrentTime,
    closeErrorDialog
  } as StateProps

  return (
    <DailyReservationsStateContext.Provider value={globalStates}>
      {children}
    </DailyReservationsStateContext.Provider>
  )
}
