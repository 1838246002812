import { Link, useNavigate } from 'react-router-dom'
import styles from 'components/common/HamburgerMenu.module.scss'
// import { logout } from 'api/contractors'
import { useLoginState } from './provider/LoginStateProvider'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'
import { useState } from 'react'
import ErrorDialog from './dialogs/messages/ErrorDialog'

/**
 *
 * @returns
 */
const HamburgerMenu = () => {
  //
  const navigate = useNavigate()
  const lState = useLoginState()

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)

  /**
   *
   */
  const logoutUser = async () => {
    try {
      await lState.logout()
      navigate('/login')
    } catch (e: unknown) {
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    }
  }

  /**
   *
   */
  const closeHamburgerMenu = () => {
    const checkbox = document.getElementById('hamburger-menu-check') as HTMLInputElement
    checkbox.checked = false
  }

  const closeErrorDialog = (): void => {
    setShowErrorDialog(false)
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  return (
    <div className={styles.hamburgerMenu}>
      <ErrorDialog
        title={dialogTitle}
        contents={dialogContents}
        show={showErrorDialog}
        onOk={closeErrorDialog}
      />
      <input type="checkbox" id="hamburger-menu-check" className={styles.menuCheck}></input>
      <label htmlFor="hamburger-menu-check" className={styles.hamburgerButton}>
        <span></span>
      </label>
      <div className={styles.dropdownMenu}>
        {lState.isAdmin() && (
          <ul>
            <Link to="/">
              <li onClick={closeHamburgerMenu}>管理者トップ</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/contractors">
              <li onClick={closeHamburgerMenu}>契約社管理</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/reservations/daily">
              <li onClick={closeHamburgerMenu}>年月日から予約</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/reservations/by-facility">
              <li onClick={closeHamburgerMenu}>施設から予約</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/facilities">
              <li onClick={closeHamburgerMenu}>施設管理</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/notifications">
              <li onClick={closeHamburgerMenu}>お知らせ管理</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/facilities/suspends">
              <li onClick={closeHamburgerMenu}>施設利用停止</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/uploadFiles">
              <li onClick={closeHamburgerMenu}>ファイル管理</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/mailTemplates">
              <li onClick={closeHamburgerMenu}>メール管理</li>
            </Link>
            <hr className={styles.divider} />
            <a href="#/" onClick={logoutUser}>
              <li onClick={closeHamburgerMenu}>ログアウト</li>
            </a>
          </ul>
        )}
        {!lState.isAdmin() && (
          <ul>
            <Link to="/">
              <li onClick={closeHamburgerMenu}>トップ</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/reservations/daily">
              <li onClick={closeHamburgerMenu}>年月日から予約</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/reservations/by-facility">
              <li onClick={closeHamburgerMenu}>施設から予約</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/contractor">
              <li onClick={closeHamburgerMenu}>契約社情報</li>
            </Link>
            <hr className={styles.divider} />
            <Link to="/inquiry">
              <li onClick={closeHamburgerMenu}>お問合せ</li>
            </Link>
            <hr className={styles.divider} />
            <a href="#/" onClick={logoutUser}>
              <li onClick={closeHamburgerMenu}>ログアウト</li>
            </a>
          </ul>
        )}
        <div className={styles.spacer} />
      </div>
    </div>
  )
}

export default HamburgerMenu
