import axios from 'api/common/axios'
import { NOTIFICATIONS_ALL, NOTIFICATIONS_BASE, NOTIFICATIONS_CURRENT } from './common/url'
import { NotificationInfo } from 'types/infos/NotificationInfo'
import { API_RESPONSE } from 'types/common/Api'
import { NotificationRow } from 'types/rows/NotificationRow'
import { RESPONSE_NG } from 'utils/constants'

/**
 *
 * @param contractorId
 */
export const findCurrentList = async (contractorId: number) => {
  try {
    const params = {
      contractorId
    }
    type retType = API_RESPONSE<NotificationRow[]>
    const response = await axios.get<retType>(NOTIFICATIONS_CURRENT, { params })

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }
    const list = response.data.data
    const ret = list.map((e: any) => {
      const dateValue = new Date(e.publishDatetime)
      e.publishDatetime = dateValue
      return e
    })

    return ret
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param contractorId
 */
export const findPublished = async (contractorId: number) => {
  try {
    const params = {
      contractorId
    }
    type retType = API_RESPONSE<NotificationRow[]>
    const response = await axios.get<retType>(NOTIFICATIONS_CURRENT, { params })

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const list = response.data.data
    const ret = list.map((e: any) => {
      const dateValue = new Date(e.publishDatetime)
      e.publishDatetime = dateValue
      return e
    })

    return ret
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

export const findAllNotifications = async (contractorId: number) => {
  try {
    const params = {
      contractorId
    }
    type retType = API_RESPONSE<NotificationRow[]>
    const response = await axios.get<retType>(NOTIFICATIONS_ALL, { params })

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    const list = response.data.data
    const ret = list.map((e: any) => {
      const dateValue = new Date(e.publishDatetime)
      e.publishDatetime = dateValue
      return e
    })

    return ret

  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 * @returns
 */
export const registerNotification = async (params: NotificationInfo) => {
  try {
    type retType = API_RESPONSE<NotificationRow[]>
    const response = await axios.post<retType>(NOTIFICATIONS_BASE, params)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param params
 * @returns
 */
export const updateNotification = async (params: NotificationInfo) => {
  try {
    type retType = API_RESPONSE<null>
    const response = await axios.put<retType>(
      `${NOTIFICATIONS_BASE}/${params.notificationId}`,
      params
    )

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}

/**
 *
 * @param notificationId
 * @returns
 */
export const deleteNotification = async (notificationId: number) => {
  try {
    type retType = API_RESPONSE<null>
    const response = await axios.delete<retType>(`${NOTIFICATIONS_BASE}/${notificationId}`)

    if (response.data.status === RESPONSE_NG) {
      throw new Error(response.data.code.toString())
    }

    return response.data.data
    //
  } catch (e) {
    console.log(e)
    throw e
  } finally {
    //
  }
}
