import { useEffect } from 'react'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ContractorForm from 'components/contractors/contractor/ContractorForm'
import { useContractorState } from 'components/contractors/contractor/ContractorStateProvider'
import { EXEC_TYPE } from 'types/common/ExecType'
import { ContractorInfo } from 'types/infos/ContractorInfo'
import { Transition } from './CommonTransition'

/**
 *
 */
interface ContractorDialogProps {
  show: boolean
  execType: EXEC_TYPE
  contractorInfo?: ContractorInfo
  onClose?: () => void
}

/**
 *
 * @returns
 */
const ContractorDialog = (props: ContractorDialogProps) => {
  //
  const { show, execType, contractorInfo, onClose } = props

  //
  const states = useContractorState()

  //
  useEffect(() => {
    // クローズ時は入力値をクリア
    if (show === false) {
      states.clear()
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  /**
   *
   */
  const handleClose = () => {
    onClose!()
  }

  return (
    <div>
      <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              契約社情報
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              <span style={{fontSize: "18px"}}>閉じる</span>
              <CloseIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ContractorForm
            execType={execType}
            isOpenForDialog
            contractorInfo={contractorInfo}
            doClose={() => onClose!()}
          />
        </div>
      </Dialog>
    </div>
  )
}

export default ContractorDialog
