import { createContext, useContext, useEffect, useState } from 'react'

import { getFacilityList } from 'api/common/masterCache'
import { ListElement } from 'types/common/ListElement'
import { ProviderProps } from 'types/common/ProviderProps'
import { ReservedInfo } from 'types/ui/ReservedInfo'
import {
  addDate,
  toWareki,
  diffByDay,
  currentFirstDate,
  lastDateOfMonth,
  firstDateOfMonth,
  diffByTimeUnit,
  toTimeUnit,
  isSameDay,
  splitByDay,
  firstDateOfCurrentMonth,
  firstDateOfNextMonth,
  firstDateOfAfterNextMonth,
  clearTimes
} from 'utils/dateUtil'
import { SuspendedInfo } from 'types/ui/SuspendedInfo'
import { findForFacility } from 'api/reservationSuspended'
import { findFacilityReservedList } from 'api/reservations'
import { useLoginState } from 'components/common/provider/LoginStateProvider'
import { DateListElement } from 'types/rows/DateListElement'
import { cloneDeep } from 'lodash'
import { useGlobalState } from 'components/common/provider/GlobalStateProvider'
import { FATAL_ERROR_MESSAGE, FATAL_ERROR_TITLE } from 'utils/messageUtil'

/**
 *
 */
interface StateProps {
  isLoading: boolean
  // 施設ドロップダウンリスト
  facilitiesList: ListElement[]
  // 選択施設ID
  targetFacilityId: number | null
  // 表示対象年月
  targetMonth: Date
  // 月日リスト（一覧の行）
  dateList: DateListElement[]
  // 予約情報の一覧
  reservedList: ReservedInfo[]
  // 利用停止情報の一覧
  suspendedList: SuspendedInfo[]
  // 選択施設情報
  targetReservation: ReservedInfo | null

  changeTargetFacilityId: (val: number | null) => void
  changeTargetMonth: (val: Date) => void
  selectTargetReservation: (target: ReservedInfo) => void

  goCurrentMonth: () => void
  goNextMonth: () => void
  goMonthAfterNext: () => void
  refreshAll: () => void

  dialogTitle: string
  dialogContents: string
  showErrorDialog: boolean
  checkCurrentTime: (datetime: Date) => boolean
  closeErrorDialog: () => void
}

/**
 *
 */
export const FacilityReservationsStateContext = createContext({} as StateProps)

/**
 *
 * @returns
 */
export const useFacilityReservationsState = () => useContext(FacilityReservationsStateContext)

/**
 *
 * @param props
 * @returns
 */
export const FacilityReservationsStateProvider = (props: ProviderProps) => {
  // Properties
  const { children } = props
  //
  const lState = useLoginState()
  const gState = useGlobalState()

  // States
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [facilitiesList, setFacilitiesList] = useState<ListElement[]>([])

  const [targetFacilityId, setTargetFacilityId] = useState<number | null>(null)
  const [targetMonth, setTargetMonth] = useState<Date>(currentFirstDate())

  const [dateList, setDateList] = useState<DateListElement[]>([])
  const [reservedList, setReservedList] = useState<ReservedInfo[]>([])
  const [suspendedList, setSuspendedList] = useState<SuspendedInfo[]>([])
  const [targetReservation, setTargetReservation] = useState<ReservedInfo | null>(null)

  const [dialogTitle, setDialogTitle] = useState<string>('')
  const [dialogContents, setDialogContents] = useState<string>('')
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false)
  // ========== 初期化処理
  /**
   * 初期表示
   */
  useEffect(() => {
    const func = async () => {
      const list = await getFacilityList()
      setFacilitiesList(list)
      refreshForSelectedMonth(targetMonth)
      // setTargetMonth(targetMonth)
    }
    func()
  }, [])

  /**
   * 対象施設、表示単位、基準日付の変化を監視 -> 予約データと利用停止データを更新する
   */
  useEffect(() => {
    setReservedList(() => [])
    setSuspendedList(() => [])

    const func = async () => {
      // 予約データ、利用停止データの更新
      await refreshAll()
    }
    func()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetFacilityId, targetMonth])

  // ========== チェンジイベント
  /**
   *
   * @param val
   */
  const changeTargetFacilityId = (val: number | null) => {
    // 通常のメインメニューなどからコールされた時、施設IDは指定されていない -> リストの先頭の施設IDを適用する
    if (val === null) {
      // F5などでリロードした時の対策
      getFacilityList().then((list) => {
        let id = 0
        if (list.length !== 0) {
          id = list[0].value as number
        }
        setTargetFacilityId(id)
      }).catch((e: any) => {
        // SessionTimeout処理
        if (gState.handleSessionExpired(e)) return
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
    } else {
      setTargetFacilityId(val)
    }
  }

  /**
   *
   * @param val
   */
  const changeTargetMonth = (val: Date) => {
    const targetMonth = clearTimes(val)
    updateMonthAndRefresh(targetMonth)
  }

  // ========== クリックイベント
  // 「当月」リンク
  const goCurrentMonth = () => {
    const currentMonth = firstDateOfCurrentMonth()
    updateMonthAndRefresh(currentMonth)
  }
  // 「翌月」リンク
  const goNextMonth = async () => {
    const nextMonth = firstDateOfNextMonth()
    await updateMonthAndRefresh(nextMonth)
  }
  // 「翌々月」リンク
  const goMonthAfterNext = () => {
    const targetMonth = firstDateOfAfterNextMonth()
    updateMonthAndRefresh(targetMonth)
  }
  /**
   *
   * @param targetDate
   */
  const updateMonthAndRefresh = async (targetMonth: Date) => {
    setTargetMonth(targetMonth)
    await refreshForSelectedMonth(targetMonth)
  }

  /**
   *
   * @param d
   */
  const refreshForSelectedMonth = (month: Date) => {
    // 指定年月の1日~最終日を算出
    const targetFirstDate = firstDateOfMonth(month)
    const targetLastDate = lastDateOfMonth(month)

    const newList: DateListElement[] = []
    for (let i = 0; i < targetLastDate.getDate()!; i++) {
      const newDate = addDate(targetFirstDate, i)
      newList.push({ index: i, date: newDate, wareki: toWareki(newDate) })
    }
    setDateList(newList)
  }

  /**
   * 予約済みセルのクリックイベント
   * @param target
   */
  const selectTargetReservation = (target: ReservedInfo) => {
    setTargetReservation(target)
  }

  // ========== プライベート関数

  /**
   * 「予約情報」と「利用停止情報」のリフレッシュを実行する
   */
  const refreshAll = async () => {
    try {
      // 「予約情報」と「利用停止情報」のリフレッシュ処理を同時並行で実行する
      await Promise.all([refresh(), refreshSuspended()])
    } catch (e) {
      // SessionTimeout処理
      if (gState.handleSessionExpired(e)) return
      // システムエラーメッセージの表示
      showFatalErrorMessage()
    } finally {
      //
    }
  }

  /**
   * 対象年月の最初の日付を取得。
   * 操作日が対象年月（1日の日を保持）よりも大きい場合は、操作日を返却する。
   * それ以外は、対象年月（の1日）を返却。
   * 要するに、操作日が属する年月の場合、操作日より前の日にちは表示しないために使用する。
   * @returns 
   */
  const getTargetMonthFrom = (): Date => {
    const today = new Date()
    if (today > targetMonth) return today
    return targetMonth
  }

  /**
   * 予約情報をリフレッシュする。
   */
  const refresh = async () => {
    if (targetFacilityId === null) return
    if (targetMonth === null) return

    const contractorId = lState.getContractorId()
    const from = getTargetMonthFrom()
    const to = lastDateOfMonth(from)

    setReservedList(() => [])
    findFacilityReservedList(targetFacilityId, from, to) //
      .then((list) => {
        const newList = list.map((e) => {
          const dateValue = new Date(e.reservationDatetime)
          e.reservationDatetime = dateValue
          e.isOwner = e.contractorId === contractorId
          e.rowIndex = diffByDay(e.reservationDatetime, from)
          return e
        })
        setReservedList(() => newList)
      })
      .catch((e: unknown) => {
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  /**
   * 利用停止情報をリフレッシュする。
   */
  const refreshSuspended = async () => {
    if (targetFacilityId === null) return
    if (targetMonth === null) return

    const from = getTargetMonthFrom()
    const to = lastDateOfMonth(from)

    findForFacility(targetFacilityId, from, to)
      .then((list) => {
        const exchanged: SuspendedInfo[] = []
        // 複数日にわたる利用停止情報が存在する場合は、日単位の情報に分割する
        list.forEach((s) => {
          const sDate = s.suspendStartDatetime
          const eDate = s.suspendEndDatetime
          // 単日の利用停止の場合 -> そのまま利用する
          if (isSameDay(sDate, eDate)) {
            exchanged.push(s)
            return
          }
          // 複数日（連続）の場合 -> 日毎の利用停止情報に分割する
          const days = splitByDay(sDate, eDate)
          const currentDays = days.filter((d) => d.from?.getMonth() === targetMonth.getMonth())
          currentDays.forEach((d) => {
            const sInfo = cloneDeep(s)
            sInfo.suspendStartDatetime = d.from!
            sInfo.suspendEndDatetime = d.to!
            exchanged.push(sInfo)
          })
        })

        const newList = exchanged.map((s) => {
          const sDate = s.suspendStartDatetime
          const eDate = s.suspendEndDatetime
          s.suspendStartDatetime = new Date(sDate)
          s.suspendEndDatetime = new Date(eDate)
          // 開始日時からの日数の差 = 行INDEXとなる
          s.rowIndex = diffByDay(sDate, from)
          // 描画開始の列INDEX
          s.startUnit = toTimeUnit(sDate)
          // 描画幅を示す数値
          s.timeUnit = diffByTimeUnit(eDate, sDate)
          return s
        })
        setSuspendedList(newList)
      })
      .catch((e: unknown) => {
        // システムエラーメッセージの表示
        showFatalErrorMessage()
      })
  }

  /**
   * システムエラーメッセージを表示する
   */
  const showFatalErrorMessage = (): void => {
    setDialogTitle(FATAL_ERROR_TITLE)
    setDialogContents(FATAL_ERROR_MESSAGE)
    setShowErrorDialog(true)
  }

  /**
   * 指定された日時が予約可能時間帯を経過している場合は、エラー終了する。
   * @param datetime
   * @returns
   */
  const checkCurrentTime = (datetime: Date): boolean => {
    const diff = (new Date().getTime() - datetime.getTime()) / 1000 / 60
    // 時刻差が30分を超えている
    // -> 指定した時間帯は、表示時には予約可能だったが、クリック時には既に使用可能時間帯を経過してしまっている
    if (diff > 30) {
      setDialogTitle('予約可能時間超過')
      setDialogContents('予約可能な時刻を過ぎています。画面から対象時間帯を指定し直して下さい。')
      setShowErrorDialog(true)
      refresh()
      refreshSuspended()
      return false
    }
    return true
  }

  const closeErrorDialog = () => {
    setShowErrorDialog(false)
  }

  // ========== 公開States
  const globalStates = {
    isLoading,
    // 施設ドロップダウンリスト
    facilitiesList,
    // 選択施設ID
    targetFacilityId,
    // 表示対象年月
    targetMonth,
    // 月日リスト（一覧の行）
    dateList,
    // 予約情報の一覧
    reservedList,
    // 利用停止情報の一覧
    suspendedList,
    // 選択施設情報
    targetReservation,

    changeTargetFacilityId,
    changeTargetMonth,
    selectTargetReservation,

    goCurrentMonth,
    goNextMonth,
    goMonthAfterNext,
    refreshAll,

    dialogTitle,
    dialogContents,
    showErrorDialog,
    checkCurrentTime,
    closeErrorDialog
  } as StateProps

  return (
    <FacilityReservationsStateContext.Provider value={globalStates}>
      {children}
    </FacilityReservationsStateContext.Provider>
  )
}
