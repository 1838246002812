import { useState } from 'react'

import PageTitle from 'components/common/PageTitle'
import styles from 'components/uploads/uploadFiles/UploadFilesForm.module.scss'
import tStyles from 'styles/utils/table.module.scss'
import { EXEC_TYPE } from 'types/common/ExecType'
import { EXEC_INSERT, EXEC_UPDATE } from 'utils/constants'
import { toWarekiTillSeconds } from 'utils/dateUtil'
import { useUploadFilesState } from './UploadFilesStateProvider'
import { DownloadFileInfo } from 'types/infos/DownloadFileInfo'
import { UploadFileStateProvider } from '../uploadFile/UploadFileStateProvider'
import UploadFileDialog from 'components/common/dialogs/UploadFileDialog'
import EmptyList from 'components/common/tables/EmptyList'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'

const UploadFilesForm = () => {
  //
  const states = useUploadFilesState()

  const [execType, setExecType] = useState<EXEC_TYPE>(EXEC_INSERT)
  const [open, setOpen] = useState<boolean>(false)

  const selectFile = (file: DownloadFileInfo) => {
    setExecType(() => EXEC_UPDATE)
    states.selectFile(file)
    openDialog()
  }

  const showDialogForInsert = () => {
    setExecType(() => EXEC_INSERT)
    openDialog()
  }

  const openDialog = () => {
    setOpen(() => true)
  }

  const closeDialog = () => {
    states.refresh()
    setOpen(() => false)
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }

  return (
    <>
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <PageTitle title="アップロードファイル一覧" />
      <div className={tStyles.tablePreInfo}>
        <div></div>
        <div>
          <button className={tStyles.insertButton} onClick={showDialogForInsert}>
            ファイル新規登録
          </button>
        </div>
      </div>
      <div className={tStyles.tableFrame}>
        <div className={`${tStyles.commonTable}  ${styles.tableWidth}`}>
          <div className={tStyles.headerRow}>
            <div className={`${tStyles.headerColumn} ${styles.col1}`}>#</div>
            <div className={`${tStyles.headerColumn} ${styles.col2}`}>お客様に公開する名称</div>
            <div className={`${tStyles.headerColumn} ${styles.col3}`}>オリジナルファイル名</div>
            <div className={`${tStyles.headerColumn} ${styles.col4}`}>掲載日</div>
            <div className={`${tStyles.headerColumn} ${styles.col5}`}>ステータス</div>
          </div>
          {states.fileList.map((file, index) => (
            <div
              className={tStyles.dataRow}
              onClick={() => selectFile(file)}
              key={file.downloadFileId}
            >
              <div className={`${tStyles.dataColumn} ${styles.col1}`}>{index + 1}</div>
              <div className={`${tStyles.dataColumn} ${styles.col2}`}>{file.publicName}</div>
              <div className={`${tStyles.dataColumn} ${styles.col3}`}>{file.originalName}</div>
              <div className={`${tStyles.dataColumn} ${styles.col4}`}>
                {toWarekiTillSeconds(file.publishDatetime)}
              </div>
              <div className={`${tStyles.dataColumn} ${styles.col5}`}>
                {file.isSuspended ? '一時停止中' : '公開中'}
              </div>
            </div>
          ))}
          <EmptyList isEmpty={states.isListEmpty} />
        </div>
      </div>

      {/* ==== オフィス登録ダイアログ ==== */}
      {/* ダイアログ全体でStateを使いたい.ここでProviderで提供しておく */}
      <UploadFileStateProvider>
        <UploadFileDialog
          execType={execType}
          uploadFileInfo={states.targetFile!}
          show={open}
          onClose={closeDialog}
        />
      </UploadFileStateProvider>
      {/* ここまで */}
    </>
  )
}

export default UploadFilesForm
