import { Helmet } from 'react-helmet-async'
import InquiryForm from 'components/inquiry/InquiryForm'
import { InquiryStateProvider } from 'components/inquiry/InquiryStateProvider'
import { TITLE_INQUIRY } from 'utils/constants'

/**
 */
const Inquiry = () => {
  return (
    <InquiryStateProvider>
      <Helmet>
        <title>{TITLE_INQUIRY}</title>
      </Helmet>
      <InquiryForm />
    </InquiryStateProvider>
  )
}

export default Inquiry
