import { useState } from 'react'
import fStyles from 'styles/utils/form.module.scss'

import CheckBox from 'components/common/atoms/CheckBox'
import DropDown from 'components/common/atoms/DropDown'
import InputText from 'components/common/atoms/InputText'
import Label from 'components/common/atoms/Label'
import TextArea from 'components/common/atoms/TextArea'
import ConfirmDialog from 'components/common/dialogs/messages/ConfirmDialog'
import ErrorDialog from 'components/common/dialogs/messages/ErrorDialog'
import NotifyDialog from 'components/common/dialogs/messages/NotifyDialog'
import { useInquiryState } from './InquiryStateProvider'
import PageTitle from 'components/common/PageTitle'
import { PrivacyPolicyStateProvider } from './PrivacyPolicyStateProvider'
import PrivacyPolicyDialog from 'components/common/dialogs/PrivacyPolicyDialog'

/**
 *
 */
const InquiryForm = () => {
  const states = useInquiryState()

  const [isShowDialog, setIsShowDialog] = useState<boolean>(false)
  const showPrivatePolicy = () => {
    setIsShowDialog(true)
  }
  const closeDialog = () => {
    setIsShowDialog(false)
  }

  /**
   *
   */
  const doOnConfirmOk = async () => {
    await states.doInquiry()
  }
  /**
   *
   */
  const doOnConfirmCancel = () => {
    states.closeConfirmDialog()
  }

  /**
   *
   */
  const closeErrorDialog = () => {
    states.closeErrorDialog()
  }
  /**
   *
   */
  const closeNotifyDialog = () => {
    states.closeNotifyDialog()
  }

  return (
    <>
      <ConfirmDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showConfirmDialog}
        onOk={doOnConfirmOk}
        onCancel={doOnConfirmCancel}
      />
      <ErrorDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showErrorDialog}
        onOk={closeErrorDialog}
      />
      <NotifyDialog
        title={states.dialogTitle}
        contents={states.dialogContents}
        show={states.showNotifyDialog}
        onOk={closeNotifyDialog}
      />
      <PageTitle title="お問合せ" />
      <section className={fStyles.detailForm}>
        <div>
          下記メールフォームよりお問い合わせ下さい。
          <br />※
          携帯電話の場合、メールの受信設定で1stbase.jpからのメールを受信可能な状態でメールフォームの送信をお願いします。
        </div>
        <div style={{ marginTop: '20px' }} />
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="メールアドレス" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="email"
              value={states.email}
              onChange={(event) => states.changeEmail(event.target.value)}
              placeholder="例）abcde@1stbase.jp"
              errorMsg={states.msg4Email}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="お問い合わせ項目" required />
          </span>
          <span className={fStyles.input}>
            <DropDown
              id="about"
              size={300}
              showEmptyOption
              emptyOptionLabel="選択してください"
              list={states.aboutList}
              value={states.aboutString}
              onChange={(event) => states.changeAboutString(event.target.value)}
              errorMsg={states.msg4About}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="お問い合わせの件名" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="title"
              value={states.title}
              onChange={(event) => states.changeTitle(event.target.value)}
              placeholder="例）料金プランについて"
              errorMsg={states.msg4Title}
            />
          </span>
        </div>
        <div className={fStyles.row} style={{ marginTop: '0px' }}>
          <span className={fStyles.label}></span>
          <span style={{ marginLeft: '5px' }}>問い合わせの要約・タイトルをご入力ください。</span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="お問い合わせの詳細" required />
          </span>
          <span className={fStyles.input}>
            <TextArea
              id="detail"
              cols={80}
              rows={5}
              value={states.detail}
              onChange={(event) => states.changeDetail(event.target.value)}
              errorMsg={states.msg4Detail}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="お名前" required />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="name"
              value={states.name}
              onChange={(event) => states.changeName(event.target.value)}
              placeholder="例）仙台 太郎"
              errorMsg={states.msg4Name}
            />
          </span>
        </div>
        <div className={fStyles.row}>
          <span className={fStyles.label}>
            <Label title="会社名" />
          </span>
          <span className={fStyles.input}>
            <InputText
              id="corpName"
              value={states.corpName}
              onChange={(event) => states.changeCorpName(event.target.value)}
              placeholder="例）株式会社ファーストベース"
              errorMsg={states.msg4CorpName}
            />
          </span>
        </div>
        {/* <div className={fStyles.row}>
          <span className={fStyles.label}></span>
          <span className={fStyles.input}>
            <CheckBox
              id="isAgreePrivatePolicy"
              label="プライバシーポリシーに同意する"
              noLeftMargin
              checked={states.isAgreePrivatePolicy}
              onChange={(event) => states.changeIsAgreePrivatePolicy(event.target.checked)}
            />
          </span>
        </div> */}

        <div className={fStyles.footerButtons}>
          <button className={fStyles.button} onClick={states.inquiry}>
            送信
          </button>
          <button className={`${fStyles.button} ${fStyles.clear}`} onClick={states.clear}>
            クリア
          </button>
        </div>
      </section>

      {/* ==== プライベートポリシーダイアログ ==== */}
      {/* <PrivacyPolicyStateProvider>
        <PrivacyPolicyDialog show={isShowDialog} onClose={closeDialog} />
      </PrivacyPolicyStateProvider> */}
      {/* ここまで */}
    </>
  )
}

export default InquiryForm
